import { createSlice } from '@reduxjs/toolkit';
import PublicsService from '../services/PublicsService';

export const homeRedux = createSlice({
    name: 'home',
    initialState: {
        noOfTradesForAnalysisTbl: 5,
        watchlistActiveSymbol: null,
        savedDailyAnalysis: [],
        banners: [],
        isBannerLoading: false,
    },
    reducers: {
        setNoOfTradesForAnalysisTbl(state, data) {
            state.noOfTradesForAnalysisTbl = data.payload;
        },
        setWatchlistActiveSymbol(state, data) {
            state.watchlistActiveSymbol = data.payload;
        },
        setSavedAnalysis(state, data) {
            state.savedDailyAnalysis = data.payload;
        },
        setBanners(state, data) {
            state.banners = data.payload;
        },
        setBannerLoading(state, data) {
            state.isBannerLoading = data.payload;
        },
    },
});

export const { setNoOfTradesForAnalysisTbl, setWatchlistActiveSymbol, setSavedAnalysis, setBanners, setBannerLoading } = homeRedux.actions;

export const getBanners = () => (dispatch) => {
    dispatch(setBannerLoading(true));
    PublicsService.pageBanners().then(({ data }) => {
        dispatch(setBanners(data.result));
        dispatch(setBannerLoading(false));
    });
}

export const getSavedAnalysis = () => (dispatch) => {
    PublicsService.savedDailyAnalysis().then(({ data }) => {
        dispatch(setSavedAnalysis(data.result));
    });
}

export default homeRedux.reducer;