/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Header from './Header';
//import util from '../util';
import AuthService from "../../services/AuthService";
let fetchModuleInterval = null;

export default function Default() {
    const navigate = useNavigate();
    const [render, setRender] = useState(0);

    const fetchModules = () => {
        AuthService.fetchModules().then(({ data }) => {
            const newData = AuthService.getLSData();
            newData.modules = data.modules;
            AuthService.setLSData(newData);
            setRender(render + 1);
        }).catch(e => {
        })
    }

    useEffect(() => {
        if (!AuthService.isLogged()) {
            navigate("/");
        }

        fetchModules();
        fetchModuleInterval = setInterval(() => {
            fetchModules();
        }, 5000);

        return () => {
            if (fetchModuleInterval) {
                clearInterval(fetchModuleInterval);
            }
        }
    }, []);


    return (
        <>
            <Header />
            <div className="main-content-pad">
                <Outlet />
            </div>
        </>
    )
}