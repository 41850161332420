import axios from "../axios";

class PublicsService {
    lists(params) {
        return axios.get("publics/callbackRequests", { params });
    }
    all(params) {
        return axios.get("publics/callbackRequests/ALL", { params });
    }
    save(data) {
        return axios.post("publics/saveCallbackRequest", data);
    }
    delete(id) {
        return axios.post("publics/deleteCallbackRequest", { id });
    }

    /** */
    pageBanners() {
        return axios.get("publics/pageBanners");
    }

    addPageBanner(data) {
        return axios.post("publics/addPageBanner", data);
    }

    deletePageBanner(id) {
        return axios.post("publics/deletePageBanner", { id });
    }

    /** */
    learningVideos(params) {
        return axios.get("publics/learningVideos", { params });
    }
    saveLearningVideo(data) {
        return axios.post("publics/saveLearningVideo", data);
    }
    deleteLearningVideo(id) {
        return axios.post("publics/deleteLearningVideo", { id });
    }

    /** */
    savedDailyAnalysis() {
        return axios.get("publics/savedDailyAnalysis");
    }

    saveDailyAnalysis(data) {
        return axios.post("publics/saveDailyAnalysis", data);
    }

    deleteDailyAnalysis() {
        return axios.post("publics/deleteDailyAnalysis");
    }

    /** */
    pageContents(params) {
        return axios.get("publics/pageContents", { params });
    }
    savePageContent(data) {
        return axios.post("publics/savePageContent", data);
    }
    deletePageContent(id) {
        return axios.post("publics/deletePageContent", { id });
    }

    /** */
    footerNavSections(params) {
        return axios.get("publics/footerNavSections", { params });
    }
    saveFooterNavSection(data) {
        return axios.post("publics/saveFooterNavSection", data);
    }
    deleteFooterNavSection(id) {
        return axios.post("publics/deleteFooterNavSection", { id });
    }

    /** */
    faqs(params) {
        return axios.get("publics/faqs", { params });
    }
    saveFaq(data) {
        return axios.post("publics/saveFaq", data);
    }
    deleteFaq(id) {
        return axios.post("publics/deleteFaq", { id });
    }

    /** */
    cmsPages(params) {
        return axios.get("publics/cmsPages", { params });
    }
    saveCmsPage(data) {
        return axios.post("publics/saveCmsPage", data);
    }
    deleteCmsPage(id) {
        return axios.post("publics/deleteCmsPage", { id });
    }
    getCmsPageDtl(slug) {
        return axios.post("publics/getCmsPageDtl", { slug });
    }

    /** */
    alertMessages(params) {
        return axios.get("publics/alertMessages", { params });
    }
    saveAlertMessages(data) {
        return axios.post("publics/saveAlertMessages", data);
    }
}

export default new PublicsService();