/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import ThemeLight from './ThemeLight';
import { useDispatch } from 'react-redux';
import { setSocketConnected } from './redux/commonRedux';
import { socket } from './socket';

function App() {
	const dispatch = useDispatch();

	const onConnect = () => {
		dispatch(setSocketConnected(true));
	}
	const onDisconnect = () => {
		dispatch(setSocketConnected(false));
	}

	useEffect(() => {
		dispatch(setSocketConnected(socket.connected));

		socket.on('connect', onConnect);
		socket.on('disconnect', onDisconnect);

		// socket.on("onMessage_TRUEDATA_BAR", (d) => {
		// 	console.log(d);
		// });

		return () => {
			socket.off('connect', onConnect);
			socket.off('disconnect', onDisconnect);
		};
	}, []);

	return (
		<React.Suspense fallback={<div style={{ padding: 20, textAlign: 'center', color: '#ccc' }}>Loading...</div>}>
			<ThemeLight />
			<React.Fragment>
				<BrowserRouter basename={"/"}>
					<AppRoutes></AppRoutes>
				</BrowserRouter>
			</React.Fragment>
			<iframe id="printiframe" name='printiframe' className="d-none" title="Print" />
		</React.Suspense>
	);
}

export default App;