export const tradeSymbols = [
    "HINDUNILVR",
    "INFY",
    "TATASTEEL",
    "AXISBANK",
    "ACC",
    "DIVISLAB",
    "PFC",
    "DABUR",
    "TRIDENT",
    "BHARATFORG",
    "HAL",
    "BANKBARODA",
    "DLF",
    "ONGC",
    "NETWORK18",
    "IRCTC",
    "INOXWIND",
    "NIFTY 50",
    "INDIA VIX",
    "NIFTY PHARMA",
    "LT",
    "IDFCFIRSTB",
    "TVSMOTOR",
    "BRITANNIA",
    "SBILIFE",
    "HAVELLS",
    "AUROPHARMA",
    "BEL",
    "AFFLE",
    "LAURUSLABS",
    "TATAMOTORS",
    "TCS",
    "JSWSTEEL",
    "KOTAKBANK",
    "ADANIPORTS",
    "CIPLA",
    "LICHSGFIN",
    "FORTIS",
    "NBCC",
    "TATAPOWER",
    "INDHOTEL",
    "PNB",
    "GODREJPROP",
    "GAIL",
    "SUNTV",
    "IRFC",
    "ADANIGREEN",
    "NIFTY INFRA",
    "NIFTY AUTO",
    "NIFTY DIV OPPS 50",
    "CONCOR",
    "KELLTONTEC",
    "BAJAJ AUTO",
    "NESTLEIND",
    "HDFCAMC",
    "DMART",
    "AUBANK",
    "BHEL",
    "BSOFT",
    "IPCALAB",
    "SUNPHARMA",
    "WIPRO",
    "HINDALCO",
    "HDFCBANK",
    "BHARTIARTL",
    "BIOCON",
    "RECLTD",
    "ABCAPITAL",
    "BAJAJHIND",
    "ICICIPRULI",
    "JSWENERGY",
    "INDIANB",
    "OBEROIRLTY",
    "BPCL",
    "ZEEL",
    "TEXRAIL",
    "TORNTPOWER",
    "NIFTY METAL",
    "NIFTY FIN SERVICE",
    "NIFTY REALTY",
    "ULTRACEMCO",
    "SUVEN",
    "EICHERMOT",
    "VBL",
    "CHOLAFIN",
    "TORNTPHARM",
    "BANDHANBNK",
    "COALINDIA",
    "COFORGE",
    "LALPATHLAB",
    "HDFCLIFE",
    "HCLTECH",
    "SAIL",
    "ICICIBANK",
    "RELIANCE",
    "LUPIN",
    "MCX",
    "BANKBARODA",
    "CENTRALBK",
    "TATACHEM",
    "M&M",
    "UNIONBANK",
    "PRESTIGE",
    "IGL",
    "PVRINOX",
    "RVNL",
    "IREDA",
    "NIFTY BANK",
    "NIFTY PSU BANK",
    "NIFTY FMCG",
    "NIFTY ENERGY",
    "EXIDEIND",
    "NATIONALUM",
    "HEROMOTOCO",
    "GODREJCP",
    "BAJFINANCE",
    "BERGEPAINT",
    "BATAINDIA",
    "NALCO",
    "CYIENT",
    "NATCOPHARM",
    "ITC",
    "TECHM",
    "JINDALSTEL",
    "SBIN",
    "MARUTI",
    "ZYDUSLIFE",
    "NMDC",
    "APOLLOTYRE",
    "MAHABANK",
    "INDUSINDBK",
    "BAJAJFINSV",
    "CANBK",
    "BRIGADE",
    "IOC",
    "TV18BRDCST",
    "TITAGARH",
    "BOROLTD",
    "NIFTY IT",
    "NIFTY MIDCAP 50",
    "NIFTY MEDIA",
    "AMBUJACEM",
    "HFCL",
    "M&M",
    "TATACONSUM",
    "MUTHOOTFIN",
    "PIDILITIND",
    "NTPC",
    "TATACOMM",
    "GLENMARK",
    "LEMONTREE",
    "ZOMATO",
    "ASHOKLEY",
    "INDIGO",
    "BEML",
    "WEBELSOLAR",
    "NIFTY PHARMA",
    "NIFTY OIL AND GAS",
    "NIFTY IND DIGITAL",
    "SIEMENS",
    "IFCI",
    "ESCORTS",
    "UBL",
    "CUMMINSIND",
    "ALUMINIUM-I",
    "ALUMINIUM-II",
    "ALUMINI-I",
    "ALUMINI-II",
    "COPPER-I",
    "COPPER-II",
    "CRUDEOILM-I",
    "CRUDEOILM-II",
    "CRUDEOIL-I",
    "CRUDEOIL-II",
    "SILVERM-I",
    "SILVERM-II",
    "GOLD-I",
    "GOLD-II",
    "GOLDM-I",
    "GOLDM-II",
    "SILVER-I",
    "SILVER-II",
    "NATGASMINI-I",
    "NATGASMINI-II",
    "NATURALGAS-I",
    "NATURALGAS-II",
    "ZINCMINI-I",
    "ZINCMINI-II",
    "LEAD-I",
    "LEAD-II",
    "LEADMINI-I",
    "LEADMINI-II",
    "USDINR-I",
    "USDINR-II",
    "EURINR-I",
    "EURINR-II",
    "GBPINR-I",
    "GBPINR-II",
    "JPYINR-I",
    "JPYINR-II"
];

export const tradeHistoryIntervals = ['1min', '2min', '3min', '5min', '10min', '15min', '30min', '60min', 'EOD'];

/* export const DAILY_ANALYSIS_DIALOG_MSGS = [
    "🤖 SELLERS RESISTANCE ZONE NEAR @", //1
    "🤖 LEVEL - 2 EXIT POINT @", //2
    "🤖 PRICE TESTING RESISTANCE LEVEL - 2 @", //3
    "🤖 SHORT - TERM EXIT POINT - 1  @", //4
    "🤖 PRICE TESTING RESISTANCE LEVEL - 1 @", //5
    "HI I'M ROBOTECH 🤖 I WILL GUIDE YOU WITH 20 PERIOD ANALYSIS MID POINT @", //6
    "🤖 PRICE TESTING SUPPORT LEVEL - 1 @", //7
    "🤖 SHORT - TERM ENTRY - SUPPORT - 1 @", //8
    "🤖 PRICE TESTING SUPPORT LEVEL - 2 @", //9
    "🤖 SAFE LEVEL - 2 ENTRY POINT @", //10
    "🤖 PRICE TESTING BUYERS STOPLOSS LEVELS @", //11
]; */

export const DAILY_ANALYSIS_DIALOG_MSGS = [
    "😎 यह STRONG RESISTANCE POINT है मार्केट में थोड़ी हलचल हो सकती है (@1 TO @2) ❌", //1
    "😎 यह LEVEL-2 के लिए सही EXIT POINT है (@LTP TO @2) 🎯🎯", //2
    "😎 PRICE RESISTANCE LEVEL-2 को टेस्ट कर रहा है (@2 TO @3) 📈 📈", //3
    "😎 यह SHORT TERM EXIT के लिए सही POINT है (@LTP TO @4) 🎯", //4
    "😎 PRICE RESISTANCE LEVEL-1 को टेस्ट कर रहा है (@5 TO @4) 📈", //5
    "मैं 😎 रोबो एडवाइजर हूँ मैं आपको PERIOD LABEL स्ट्रेटेजी के लिए गाइड करूँगा MID-POINT (@LTP TO @6) 👍", //6
    "😎 PRICE SUPPORT LEVEL-1 को टेस्ट कर रहा है (@6 TO @7) 📉", //7
    "😎 यहाँ SHORT-TERM ENTRY के लिए सोच सकते हें (@LTP TO @8) 📊", //8
    "😎 PRICE SUPPORT LEVEL-2 को टेस्ट कर रहा है (@8 TO @9) 📉📉", //9
    "😎 यह SAFE LEVEL-2 ENTRY POINT है    (@LTP TO @10) 📊📊", //10
    "😎 PRICE BUY STOPLOSS लेवल को टेस्ट कर रहा है (@10 TO @11) 😡..💣.💥", //11
];

export const QUOTES = [
    "Letting losses run is the most serious mistake made by most investors.|William O'Neil",
    "Everything should be made as simple as possible but not Simpler.|Albert Einstein",
    "I always define my risk, and I don't have to worry about it.| Tony Saliba",
    "Everyday I assume every position I have is wrong.| Paul Tudor Jones",
    "Don't test the depth of the river with both your feet while taking the risk.| Warren Buffett",
    "For investors as a whole, returns decrease as motion increases.| Warren Buffett",
    "Invest in yourself as much as you can; you are your own biggest asset by far.| Warren Buffett",
    "It's much better to buy a wonderful company at a fair price than a suitable company at a wonderful price.| Warren Buffett",
    "Look at market fluctuations as your friend rather than your enemy.| Warren Buffett",
    "Successful investing takes time, discipline and patience.| Warren Buffett",
    "The market is a device for transferring money from the impatient to the patient.| Warren Buffett",
    "Information is not knowledge.The only source of knowledge is experience.You need experience to gain wisdom.| Albert Einstein",
    "Don't listen to the person who has the answers; listen to the person who has the questions.|Albert Einstein",
    "Insanity: doing the same thing over and over again and expecting different results.| Albert Einstein",
    "There is no vaccine against stupidity.| Albert Einstein",
    "We cannot get to where we dream of being tomorrow unless we change our thinking today.| Albert Einstein",
    "Knowledge and ego are directly related.the less knowledge, the greater the ego.| Albert Einstein",
    "Just because you believe in something does not mean that it is true.| Albert Einstein",
    "What we know is a drop, what we don't know is an ocean.|Isaac Newton",
    "The goal of a successful trader is to make the best trades.Money is secondary.| Alexander Elder",
    "In investing, what is comfortable is rarely profitable.| Robert Arnott",
    "If you personalize losses, you can't trade.| Bruce Kovner",
    "It's not that I'm so smart; it's just that I stay with problems longer.| Albert Einstein",
    "It's not what we do once in a while that shapes our lives.It's what we do consistently.| Anthony Robbins",
    "An investment in knowledge pays the best interest.| Benjamin Franklin",
    "In the short run, the market is a voting machine, but in the long run it is a weighing machine.| Benjamin Graham",
    "In trading / investing, it's not about how much you make but rather how much you don't lose.| Bernard Baruch",
    "Never let a win go to your head, or a loss to your heart.| Chuck D",
    "It does not matter how slowly you go as long as you do not stop.| Confucius",
    "Trade What's Happening… Not What You Think Is Gonna Happen.| Doug Gregory",
    "Before I enter a trade, I set stops at a point which the chart sours.| Ed Seykota",
    "Short term volatility is greatest at turning points and diminishes as a trend becomes established.| George Soros",
    "Accepting losses is the most important single investment device to ensure the safety of capital.| Gerald M.Loeb",
    "The best traders have trading habits, daily routines they repeat day after day.| Henrique M.Simões",
    "Take your profits or someone else will take them for you.| J.J.Evans",
    "Don't blindly follow someone, follow[the] market and try to hear what it is telling you.| Jaymin Shah",
    "I trade my own information and follow my own methods.| Jesse Livermore",
    "Only the game, can teach you the game.| Jesse Livermore",
    "The obvious rarely happens, the unexpected constantly occurs.| Jesse Livermore",
    "Hope is bogus emotion that only costs you money.| Jim Cramer",
    "If it's obvious, it's obviously wrong.| Joe Granville",
    "Time is your friend; impulse is your enemy.| John Bogle",
    "I get real, real concerned when I see trading strategies with too many rules(you should too).| Larry Connors",
    "Frankly, I don't see markets; I see risks, rewards, and money.| Larry Hite",
    "If you don't respect risk, eventually they'll carry you out.| Larry Hite",
    "All you need is one pattern to make a living.| Linda Raschke",
    "Never, ever argue with your trading system.| Michael Covel",
    "The men on the trading floor may not have been to school, but they have Ph.D.'s in man's ignorance.| Michael M.Lewis",
    "I believe in analysis and not forecasting.| Nicolas Darvas",
    "Everyday I assume every position I have is wrong.| Paul Tudor Jones",
    "Don't test the depth of the river with both your feet while taking the risk.| Warren Buffett",
    "For investors as a whole, returns decrease as motion increases.| Warren Buffett",
    "I'll tell you how to become rich: close all doors, beware when others are greedy and be greedy when others are afraid.| Warren Buffett",
    "Invest in yourself as much as you can; you are your own biggest asset by far.| Warren Buffett",
    "It's much better to buy a wonderful company at a fair price than a suitable company at a wonderful price.| Warren Buffett",
    "Look at market fluctuations as your friend rather than your enemy.| Warren Buffett",
    "Successful investing takes time, discipline and patience.|Warren Buffett",
    "The market is a device for transferring money from the impatient to the patient.| Warren Buffett",
    "Information is not knowledge.The only source of knowledge is experience.You need experience to gain wisdom.| Albert Einstein",
    "Don't listen to the person who has the answers; listen to the person who has the questions.|Albert Einstein",
    "Insanity: doing the same thing over and over again and expecting different results.|Albert Einstein",
    "There is no vaccine against stupidity.|Albert Einstein",
    "We cannot get to where we dream of being tomorrow unless we change our thinking today.|Albert Einstein",
    "Knowledge and ego are directly related.the less knowledge, the greater the ego.| Albert Einstein",
    "Just because you believe in something does not mean that it is true.| Albert Einstein",
    "What we know is a drop, what we don't know is an ocean.|Isaac Newton",
    "In investing what is comfortable is rarely profitable.|Robert Arnott",
    "Never, ever argue with your trading system.|Michael Covel",
    "I believe in analysis and not forecasting.|Nicolas Darvas",
    "Time is your friend; impulse is your money.|John Bogle",
    "The rich invest in time, the poor invest in money.|Warren Buffet.",
    "An investor without investment objectives is like a traveler without a destination.|Ralph Seger",
    "Investing is the intersection of economics and psychology.|Seth Klarman",
    "An investment in knowledge pays the best interest.|Benjamin Franklin",
    "Know what you own, and know why you own it.|Peter Lynch",
    "I trade my own information and follow my own methods.|Unknown",
    "Markets are never wrong, but opinions often are.|Unknown",
    "In investing, what is comfortable is rarely profitable.|",
    "Risk comes from not knowing what you're doing.|Warren Buffett",
    "Luck is preparation meeting opportunity.|Oprah",
    "Every battle is won or lost before it's ever fought.|Sun Tzu",
    "The goal of a successful trader is to make the best trades.|Alexander Elder",
    "Yesterday's home runs don't win today's games.| Babe Ruth",
    "Losing is as much part of trading as winning.|Mark Zuckerberg",
    "The biggest risk of all is not taking one.|Mellody Hobson",
    "In investing, what is comfortable is rarely profitable.|Robert Arnott",
    "Remember, cash is a fact, profit is an opinion.|Alfred Rappaport",
    "Stocks Don't Move. They Are Moved.|Mr. Cooper.",
    "No price is too low for a bear or too high for a bull.|Unknown",
    "Your investment should be equally risky and rewarding.|Anuj Jasani",
    "Paper gains do not equal cash on cash returns.|Henry Joseph-Grant",
    "The success of an investment is all about its execution and is not about its outcome.|Naved Abdali",
    "Cash is never a king, especially when people repeat the phrase often.|Naved Abdali",
    "The outcome of an investment does not make it right or wrong.|Naved Abdali",
    "Short-term price fluctuation is not the real risk. It is an opportunity.|Naved Abdali",
    "Prices are dominated by the minority, and values are governed by the majority.|Naved Abdali",
    "Most of the assets can be a good investment if you buy at the right price.|Naved Abdali",
    "Traders, as a group, always fall short of investors.|Naved Abdali",
    "Your best analysis is done by asking the question, 'Where is the loser?|Jason Alan Jankovsky",
    "You create your own game in your mind based on your beliefs, intents, perception and rules.|Unknown",
    "Know what you own, and know why you own it.|Peter Lynch",
    "the price of a stock as quoted on an exchange.|Unknown",
    "Invest for the long haul. Don't get too greedy and don't get too scared.|Unknown",
    "If you don't follow stock market you are missing some extra drama.|Unknown",
    "The trend is your friend until the end when it bends.|Ed Seykota",
    "Take your profits or someone else will take them for you.|J.J. Evans",
    "I don't buy long stocks on a scale down, I buy on scale-up.|Jesse Livermore",
    "I trade my own information and follow my own methods.|Jesse Livermore",
    "Money is made by sitting, not trading.|Jesse Livermore",
    "Frankly, I don't see markets; I see risks, rewards, and money.|Larry Hite",
    "All the math you need in the stock market you get in the fourth grade.|Peter Lynch",
    "In a bull market, one can only be long or on the sidelines. Remember, not having a position is a position.|Richard Rhodes",
    "Trade the market in front of you, not the one you want!|Scott Redler",
    "There is a huge difference between a good trade and good trading.|Steve Burns",
    "Bulls make money, bears make money, pigs get slaughtered.|Unknown",
    "In trading, everything works sometimes and nothing works always.|Unknown",
    "Markets change their behavior faster than people can change their minds… That is why intraday trading is so difficult.|Unknown",
    "Sometimes the best trade is no trade.|Unknown",
    "For investors as a whole, returns decrease as motion increases.|Warren Buffett",
    "Successful investing takes time, discipline and patience.|Warren Buffett",
    "In order to succeed, you first have to be willing to experience failure.|Yvan Byeajee",
    "The mind is a fascinating instrument that can make or break you.|Yvan Byeajee",
    "Trading effectively is about assessing probabilities, not certainties.|Yvan Byeajee",
    "All you need is one pattern to make a living.|Linda Raschke",
    "The desire to perform all the time is usually a barrier to performing over time.| Robert A. Olstein",
    "If you can't handle stress you won't handle success.|Unknown",
    "Invest in yourself. Your career is the engine of your wealth.|Paul Clitheroe",
    "The individual investor should act consistently as an investor and not as a speculator.| Ben Graham",
    "Know what you own, and know why you own it.| Peter Lynch",
    "Volatility is greatest at turning points, diminishing as a new trend becomes established.| George Soros",
    "The main purpose of stock market is to make fools of as many men as possible.| Bernard Baruch",
    "Remember, your goal is to trade well, not to trade often.| Alexander Elder",
    "Sometimes your best investments are the ones you don't make.| Donald Trump",
    "There are a million ways to make money in the markets. The irony is that they are all very difficult to find.|Jack D. Schwager",
    "I don't lose much on trades, because I wait for the exact right moment.| Mark Weinstein",
    "Don't focus on making money; focus on protecting what you have.|Paul Tudor Jones",
    "Every moment in the market is unique.|Mark Douglas",
    "The consistency you seek is in your mind, not in the markets.|Mark Douglas",
    "The hard, cold reality of trading is that every trade has an uncertain outcome.|Mark Douglas",
    "No one is Born a Great Trader, one learns by trading! So Never Give Up!.|Unknown",
    "In investing, what is comfortable is rarely profitable.|Robert Arnott",
    "If you don't bet, you can't win.|Unknown",
    "Knowing when to sell is much harder than knowing when to buy.|Unknown",
    "Remember that the stock market is a manic depressive.|Unknown",
    "Rule No. 1: Never lose money. Rule No. 2: Never forget rule No.1.| Warren Buffett",
    "If stock market experts were so expert, they would be buying stock, not selling advice.|Unknown",
    "Compound interest is the eighth wonder of the world. He who understands it, earns it. He who doesn't, pays it.| Albert Einstein",
    "Rapidly changing industries are the enemy of the investor.|Unknown",
    "Wall Street makes its money on activity, you make your money on inactivity.|Warren Buffett",
    "The stock market is filled with individuals who know the price of everything, but the value of nothing.|Phillip Fisher",
    "Always do a post-analysis of your stock market trades so that you can learn from your successes and mistakes.|Unknown",
    "if you are persistent you will get it. if you are consistent you will keep it.|Unknown",
    "Learn every day, but especially from the experiences of others. it's cheaper!.|John Bogle",
    "Emotions are your worst enemy in the stock market.|Don Hays",
    "Success consists of going from failure to failure without loss of enthusiasm.|Winston Churchill",
    "stop trading freedom for the illusion of security.| Erik Prince",
    "Successful investing involves doing a few things right and avoiding serious mistakes.|Unknown",
    "Never depend on a single income. Make the investment to create a second source.|Unknown",
    "In the business world, the rearview mirror is always clearer than the wind shield.|Unknown",
    "Time is your friend; impulse is your enemy.|John Bogle",
    "Never invest in any idea you can't illustrate with a crayon.|Peter Lynch",
    "Learn to take losses. The most important thing in making money is not letting your losses get out of hand.|Martin Schwartz",
    "Every day I assume every position I have is wrong.|Paul Tudor Jones",
    "Do not be embarrassed by your failures, learn from them and start again.|Richard Branson",
    "Some people seem to like to lose, so they win by losing money.|Ed Seykota",
    "Investment is most successful when it is most business like.| Ben Graham",
    "No price is too low for a bear or too high for a bull.|Unknown",
    "An important key to investing is to remember that stocks are not lottery tickets.|Peter Lynch",
    "It's never too late to learn.|Malcolm Forbes",
    "Index investing outperforms active management year after year.|Jim Rogers",
    "Knowledge can only be volunteered it cannot be conscripted.|Unknown",
    "In the context of real need few people will withhold their knowledge.|Unknown",
    "Only those who are asleep make no mistakes.|Ingvar Kamprad.|Unknown",
    "Commodities tend to zig, when the equity markets zag.|Jim Rogers",
    "A winning strategy must include losing.|Rich Dad. ",
    "Invest for the long haul. Don't get too greedy and don't get too scared.|Shelby M.C. Davis",
    "Buy not on optimism, but on arithmetic.|Benjamin Graham",
    "Spend each day trying to be a little wiser than you were when you woke up.|Charlie painful Munger",
    "Behind every stock is a company. Find out what it's doing.| Peter Lynch",
    "Invest for the long-term.| Lou Simpson",
    "The stock market is overpriced. Everything is overpriced. Junk is king.|Jeremy Grantham",
    "Success consists of going from failure to failure without loss of enthusiasm.|Winston Churchill",
    "Don't aim for success if you want it; just do what you love and believe in, and it will come naturally.|David Frost",
    "There is only one success - to be able to spend your life in your own way.|Christopher Morley",
    "Belief in oneself is one of the most important bricks in building any successful venture.|Lydia M. Child",
    "Trade your plan not your emotions.|Unknown",
    "Time in the market beats timing the market.|Unknown",
    "Successful Investing Starts with courage.|Unknown",
    "Don't be afraid to give up the good to go for the great.|John D. Rockefeller",
    "The secret of your future is hidden in your daily routine.|Unknown",
    "Remember: difficult does not mean impossible.|Unknown",
    "Don't compare yourself to others. Be your own competition.|Unknown",
    "Successful people are not gifted ;they just work hard than,succeed on purpose.|Unknown",
    "Don't fear failure.fear being in the exact same place next year as you are today.|unknown",
    "The dumbest reason in the world to buy a stock is because it's going up.|warren buffet",
    "Never test the depth of river with both feet.|warren buffet",
    "You mind is like a bank.what you deposit is what you can withdraw.|warren buffet",
    "An idiot with a plan can  beat a genius without a plan.|warren buffet",
    " You will never reach your destination if you stop and throw stones at every dog that barks.|Unknown",
    "Imagination is more important than knowledge. knowledge is limited, imagination encircle the world.|Albert Einstein",
    "Intellectuals solve problems; geniuses prevent them.|Albert Einstein",
    "The only reason for time is so that everything doesn't happen at once.|Albert Einstein",
    "Education is not the learning of facts,but the training of the mind to think.|Albert Einstein",
    "Education is the most powerful weapon ,we can use to change the world.|Nelson mandela",
    "Build your own dreams or someone else will hire you to build theirs.|farrah gray",
    "you can't fall if you don't climb.but there's no joy in living your whole life on the ground.|Unknown",
    "The secret to creativity is knowing how to hide your sources.|Albert Einstein",
    "Insanity: doing the same thing over again and excepting different results.|albert Einstein",
    "When they call you crazy, remember that great ideas don't come from average mind.|albert Einstein",
    "Nothing is permanent in this world, not even our troubles.|Charlie Chaplin",
    "Don't listen to the person who has the answers; listen to the person who has the question.|Unknown",
    "life is like a riding a bicycle to keep  your balance ,you must keep moving.|Unknown",
    "Winning isn't everything, but it sure beats anything that comes in second.|Bear Bryant",
    "A person with a new idea is a crank until the idea succeeds.|Mark Twain",
    "It is no use saying, 'We are doing our best.' You have got to succeed in doing what is necessary.|Winston Churchill",
    "Don't be afraid to share what you know, because you know it better than anyone else!|Bob Buckman",
    "The empires of the future are the empires of the mind.|Winston Churchill",
    "Communities of practice are the heart and soul of knowledge sharing.|Unknown",
    "Share your knowledge. It is a way to achieve immortality.|Dalai Lama",
    "We are drowning in information but starved for knowledge.|John Naisbitt",
    "Trust yourself, you know more than you think you do.|Benjamin Spock",
    "Knowledge rests not upon truth alone, but upon error also.|Carl Jung",
    "An investment in knowledge always pays the best interest.|Benjamin Franklin",
    "Give them quality that's the best kind of advertising.|Unknown",
    "The key making money in stocks is not get scared out of them.|peter lynch",
    "No price is too low for a bear or too high for a bull.|Unknown",
    "More money is lost anticipating the changes in the overall stock market than any other way of investing.|peter lynch",
    "spend at least as much time researching a stock as you would choosing a refrigator.|peter lynch",
    "Stock market is merciful-it always gives the nincompoop a second chance.|peter lynch. ",
    "Numbers and ratios are only as good as the management reporting them.|Unknown",
    "You should welcome a bear market, since it puts stocks back on sale.|Jason Zweig. ",
    "Unforeseen events are precisely that: unforeseeable. So, timing the market is for biased investors.|Coreen T. Sol",
    "In the stock market to own a profit you need to lose something and to lose you need to have profit to take risks.| Akshay Kumar",
    "Stock analysis and investing is not rocket science, but it needs a little conscience.|Unknown",
    "The growing business of a company and the rising price of the stock is just an iron-magnet combo.|Unknown",
    "There will be good years and there will be bad years, but the compounding will continue on unabated.|Pietros Maneos",
    "Markets can be volatile from time to time; however, stock prices follow earnings accumulation over the long term.| Naved Abdali",
    "Over the long term, and I mean a very long term, markets are efficient.|Unknown",
    "Never invest in stocks with borrowed money or a faint heart. Both are fatal.|Unknown",
    "Stop going for the easy buck and produce something with your life. Create instead of living off the buying and selling of others.|Unknown",
    "A stock screening feature is then used to find the leading stocks within the leading sectors.|Debabrata (David) Das",
    "On the horizon ... holding time,quantity and price of shares become quite irrelevant.|Sandeep Sahajpal",
    "This, today, right now, will seem like the good old days in 2 weeks. Things are about to get a hell of a lot worse.|Oliver Markus Malloy",
    "Effective economic development is fostered by a resourceful society.|Wayne Chirisa",
    "Confidence is not 'I will profit on this trade.' Confidence is I will be fine if I don't profit from this trade.|Yvan Byeajee",
    "Trading doesn't just reveal your character, it also builds it if you stay in the game long enough.|Yvan Byeajee",
    "The expectation that you bring with you in trading is often the greatest obstacle you will encounter.|Unknown",
    "A quiet mind is able to hear intuition over fear.| Yvan Byeajee",
    "In order to succeed, you first have to be willing to experience failure.|Unknown",
    "When you learn to let go of the need to be right, being wrong gradually lose its power to disturb you.|Unknown",
    "The process by which one accumulates money is so simple, yet so hard to implement for most.|Yvan Byeajee",
    "You have power over how you'll respond to uncertainty.|Unknown",
    "Trading effectively is about assessing probabilities, not certainties.|Unknown",
    "You will never find fulfilment trading the markets if you don't learn to appreciate and be satisfied with what you already have.|Unknown",
    "There is a huge amount of freedom that is derived from not fighting the market.|Unknown",
].map(rw => {
    const a = rw.split("|");
    return { quote: a[0], author: a[1] };
});

export const tradingRangeLabels = [
    { k: 'sell_resistance', l: 'RESISTANCE ZONE', color: '#00B050', active: false },
    { k: 'tgt2', l: 'EXIT-2', color: '#808080', active: false },
    { k: 'tgt1', l: 'EXIT-1', color: '#0000FF', active: false },
    { k: 'trade_price', l: 'TRADE MEDIAN', color: '#00B050', active: false },
    { k: 'st_buy', l: 'ENTRY-1', color: '#C00000', active: false },
    { k: 'level2_buy', l: 'ENTRY-2', color: '#808080', active: false },
    { k: 'sl_buy', l: 'ENTRY/STOP-LOSS', color: '#FF0000', active: false },
];

export const alertDialogMessages = [
    `🤖 CLICK ‘ANALYZE NOW’ BUTTON AGAIN! IF ABOVE (@1) 🔍`, //1
    `🤖 PRICE TESTING MAJOR RESISTANCE ZONE, POSITIVE ➕ IF ABOVE (@2) NEGATIVE ⛔ IF BELOW @3 👍`, //2
    `🤖 STRONG RESISTANCE ZONE NEAR (@2 TO @3) ❌`, //3
    `🤖 LOGGED_USER_NAME GOOD POINT FOR EXIT LEVEL-2  (@LTP TO @4) 🎯🎯`, //4
    `🤖 PRICE TESTING RESISTANCE LEVEL-2 (@4 TO @5) 📈📈`, //5
    `🤖 LOGGED_USER_NAME GOOD POINT FOR SHORT-TERM EXIT-1  (@LTP TO @6) 🎯`, //6
    `🤖 PRICE TESTING RESISTANCE LEVEL-1 (@6 TO @7) 📈 `, //7
    `HI LOGGED_USER_NAME I'M 🤖ROBO-TECH ADVISOR I’LL GUIDE YOU WITH PERIOD LABEL ANALYSIS PRICE AT MID-POINT (@LTP TO @8) 👍`, //8
    `🤖 PRICE TESTING SUPPORT LEVEL-1 (@8 TO @9) 📉`, //9
    `🤖 LOGGED_USER_NAME ANALYZE NOW! FOR SHORT-TERM ENTRY POINT LEVEL-1 (@LTP TO @10) 📊`, //10
    `🤖 PRICE TESTING SUPPORT LEVEL-2 (@10 TO @11) 📉📉`, //11
    `🤖 LOGGED_USER_NAME ANALYZE NOW! FOR SAFE LEVEL-2 ENTRY POINT (@LTP TO @12) 📊📊`, //12
    `🤖 PRICE CAN TURN NEGATIVE IF BELOW (@12 TO @13) 🤔❌`, //13
    `🤖 PRICE TESTING ENTRY STOPLOSS LEVELS IF BELOW (@14) 😡..💣. 💥`, //14
    `🤖 PRICE TESTING MAJOR SUPPORT ZONE POSITIVE ➕IF ABOVE (@14) ELSE, NEGATIVE ⛔ IF BELOW @15 👎 `, //15
];

export const dailyAnalysisPeriodList = [
    { value: 0, label: 'Intraday' },  //1min 50 period
    { value: 1, label: 'Daily' }, //5min 50 period
    { value: 5, label: '1 Week' }, //60min 30 period
    { value: 10, label: '2 Weeks' },
    { value: 15, label: '3 Weeks' },
    { value: 22, label: '1 Month' },
    { value: 44, label: '2 Months' },
    { value: 66, label: '3 Months' },
    { value: 132, label: '6 Months' },
    { value: 264, label: '1 Year' },
];

export const dailyAnalysisPeriodLabel = (val, interval = '') => {
    if (`${val}` === '50' && interval === '1min') {
        return 'Intraday';
    }
    if (`${val}` === '50' && interval === '5min') {
        return 'Daily';
    }
    if (`${val}` === '30' && interval === '60min') {
        return '1 Week';
    }

    const row = dailyAnalysisPeriodList.find(v => v.value * 1 === val * 1);
    return row ? row.label : '';
}