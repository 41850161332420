import { createSlice } from '@reduxjs/toolkit';

export const commonRedux = createSlice({
    name: 'common',
    initialState: {
        isHomeChartMaximize: false,
        analysisAlertMsg: '',
        tickData: null, //not in use
        isSocketConnected: false
    },
    reducers: {
        setHomeChartMaximize(state, data) {
            state.isHomeChartMaximize = data.payload;
        },
        setAnalysisAlertMsg(state, data) {
            state.analysisAlertMsg = data.payload;
        },
        setTickData(state, data) { //not in use
            const tick = state.tickData || {};
            tick[data.payload.Symbol] = data.payload;
            state.tickData = tick;
        },
        setSocketConnected(state, data) {
            state.isSocketConnected = data.payload;
        },
    },
});

export const { setHomeChartMaximize, setAnalysisAlertMsg, setTickData, setSocketConnected } = commonRedux.actions;

export default commonRedux.reducer;