/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import PublicsService from '../../../services/PublicsService';

import {
    Row,
    Col,
    //Button,
    message,
    Modal,
    Input,
} from 'antd';

import {
    //MailOutlined
} from '@ant-design/icons';

export default function RequestCallbackButton() {
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [fd, setFd] = useState({});

    const handleChange = (v, k) => {
        setFd({ ...fd, [k]: v });
    }

    const saveCalbbackRequest = () => {
        message.destroy();
        setLoading(true);
        PublicsService.save(fd).then(({ data }) => {
            message.success(data.message || 'We have received your message. Will will revert ypou soon.');
            setShowModal(false);
            setFd({});
        }).catch(e => {
            message.error(e.message);
        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        <>
            {/* <Button danger onClick={() => setShowModal(true)}>
                <MailOutlined />
                Request Callback
            </Button> */}

            <div className='link' onClick={() => setShowModal(true)} title='Request a Callback'>
                <img src="assets/reqcallback.png" alt="" height={38} />
            </div>

            <Modal
                title="Request Callback"
                open={showModal}
                okText="Send Request"
                onOk={saveCalbbackRequest}
                okButtonProps={{ loading }}
                onCancel={() => setShowModal(false)}
                destroyOnClose
                maskClosable={false}
                width={600}
            >
                <Row gutter={[16, 16]}>
                    <Col span="24">
                        <label className="req">Name</label>
                        <Input value={fd.name || ''} onChange={e => handleChange(e.target.value, 'name')} />
                    </Col>
                    <Col span="24">
                        <label className="req">Email</label>
                        <Input value={fd.email || ''} onChange={e => handleChange(e.target.value, 'email')} />
                    </Col>
                    <Col span="24">
                        <label className="req">Mobile</label>
                        <Input value={fd.mobile || ''} onChange={e => handleChange(e.target.value.replace(' ', ''), 'mobile')} maxLength="10" addonBefore="+91" />
                    </Col>
                    <Col span="24">
                        <label className="req">Message</label>
                        <Input.TextArea rows="4" value={fd.msg || ''} onChange={e => handleChange(e.target.value, 'msg')} />
                    </Col>
                </Row>
            </Modal>
        </>
    )
}