import axios from "../axios";

class CustomerService {
    register(data) {
        return axios.post("publics/register", data);
    }

    customers(params) {
        return axios.get("customers", { params });
    }
    allCustomers(params) {
        return axios.get("customers/ALL", { params });
    }
    saveCustomer(data) {
        return axios.post("saveCustomer", data);
    }
    deleteCustomer(id) {
        return axios.post("deleteCustomer", { id });
    }
}

export default new CustomerService();