/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PublicsService from "../../services/PublicsService";
import SocialIcons from '../../pages/public/tpl/SocialIcons';

import {
    Grid, Row, Col
} from 'antd';

import {
    PhoneFilled,
    MailOutlined
} from '@ant-design/icons';

export default function PublicFooter() {
    const screens = Grid.useBreakpoint();
    const isDesktop = screens.xxl || screens.xl || screens.lg || screens.md;
    const [navSections, setNavSections] = useState([]);

    useEffect(() => {
        PublicsService.footerNavSections().then(({ data }) => { setNavSections(data.result) });
    }, []);

    const stripInfo = navSections.length > 0 ? navSections[0].strip_info : '';

    const contactUsLine = (
        <>
            <MailOutlined /> <a href="mailto:contactus@tradesimply.io">contactus@tradesimply.io</a>
        </>
    )

    return (
        <div className='public-footer mt-auto'>
            <div style={{ padding: '0 24px 15px 24px' }}>
                {isDesktop ? (
                    <div className='d-flex align-items-center justify-content-between mb10'>
                        <div>
                            <Link to="/">
                                <img src="logo.png" width={isDesktop ? "180px" : "120px"} alt="" />
                            </Link>
                        </div>
                        <div>
                            <div className='fs13 text-center'>Our Technology Partners:</div>
                            <div className='d-flex align-items-center' style={{ gap: '10px' }}>
                                <img src="assets/zoholog.png" width={"100px"} alt="" />
                                <img src="assets/ms-azure-logo.png" width={"140px"} alt="" />
                            </div>
                        </div>
                        <div>
                            <SocialIcons showcallback />
                            <div style={{ paddingTop: '20px', textAlign: 'center', fontWeight: '600' }}>
                                {contactUsLine}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className='pb10'>
                        <div className='d-flex align-items-center justify-content-between mb10'>
                            <div>
                                <Link to="/">
                                    <img src="logo.png" width={isDesktop ? "180px" : "120px"} alt="" />
                                </Link>
                            </div>
                            <div>
                                <div style={{ fontWeight: '600' }}>
                                    {contactUsLine}
                                </div>
                            </div>
                        </div>
                        <SocialIcons showcallback />
                    </div>
                )}

                <div style={{ overflow: 'hidden' }}>
                    <Row gutter={[64, 24]}>
                        <Col>
                            <div>
                                <div className='bold700 fs16 mb5'>
                                    Trade Simply Financial Pvt. Ltd.
                                </div>
                                <div>
                                    GDITL NORTH EX-TOWER,<br />910 & 911, A-09, NETAJI SUBASH PLACE, PITAMPURA,<br />Delhi - 110034
                                </div>
                                <div>
                                    CIN: U67200DL2020PTC373778
                                </div>
                                <div>
                                    GST: 07AAICT2552K1Z3
                                </div>
                                <div>
                                    Start-up certification: DIPP126483
                                </div>
                                <div>
                                    Udyam Reg. Number: UDYAM-DL-04-0040970
                                </div>
                                <div style={{ color: '#000', fontWeight: '600' }}>
                                    Microsoft Founder Hub Level-III
                                </div>
                            </div>
                        </Col>
                        {navSections.map((v) => (
                            <Col key={v.id}>
                                <div className='d-flex' style={{ flexDirection: 'column', gap: '5px' }}>
                                    {v.links.map((rw, i) => (
                                        <a key={i} target={rw.link.includes('tradesimply.io') ? '_self' : '_blank'} href={rw.link} rel="noreferrer" style={{ color: '#333' }}>
                                            {rw.name}
                                        </a>
                                    ))}
                                </div>
                            </Col>
                        ))}
                    </Row>
                </div>
            </div>
            {!!stripInfo &&
                <div className='bold600' style={{ background: '#1890ff', padding: '7px 24px', color: '#fff' }}>
                    {stripInfo}
                </div>
            }
        </div>
    )
}