/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

/* import {
    Button,
} from 'antd';

import {
    WechatOutlined
} from '@ant-design/icons'; */

export default function ChatWithUsButton() {
    return (
        <>
            {/* <Button type="danger" size="large" block><WechatOutlined /> Chat with us</Button> */}
            <div className='link'>
                <img src="assets/chatwithus.png" alt="" height={40} />
            </div>
        </>
    )
}