/* eslint-disable react-hooks/exhaustive-deps */
import { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import DefaultLayout from './layouts/default/Default';
import PublicLayout from './layouts/public/PublicLayout';

const lazyWithRetry = (componentImport) => {
    const lsKey = 'page-has-been-force-refreshed-stocktrade';
    return lazy(async () => {
        const pageHasAlreadyBeenForceRefreshed = JSON.parse(
            window.sessionStorage.getItem(lsKey) || 'false'
        );

        try {
            const component = await componentImport();
            window.sessionStorage.setItem(lsKey, 'false');
            return component;
        } catch (error) {
            if (!pageHasAlreadyBeenForceRefreshed) {
                window.sessionStorage.setItem(lsKey, 'true');
                return window.location.reload();
            }
            throw error;
        }
    });
}

const Home = lazyWithRetry(() => import('./pages/public/Home'));
const Learning = lazyWithRetry(() => import('./pages/public/Learning'));
const Terms = lazyWithRetry(() => import('./pages/public/Terms'));
const Support = lazyWithRetry(() => import('./pages/public/Support'));
const PageContent = lazyWithRetry(() => import('./pages/public/PageContent'));
const CmsPageContent = lazyWithRetry(() => import('./pages/public/CmsPageContent'));

//const Login = lazyWithRetry(() => import('./pages/newlogin/Login'));
const Dashboard = lazyWithRetry(() => import('./pages/dashboard/Dashboard'));
const Roles = lazyWithRetry(() => import('./pages/Roles'));
const Users = lazyWithRetry(() => import('./pages/Users'));
const ChangePassword = lazyWithRetry(() => import('./pages/ChangePassword'));
const Profile = lazyWithRetry(() => import('./pages/Profile'));

//const Clients = lazyWithRetry(() => import('./pages/client/Clients'));

const Products = lazyWithRetry(() => import('./pages/master/Products'));
const PageBanners = lazyWithRetry(() => import('./pages/master/PageBanners'));
const CallbackRequests = lazyWithRetry(() => import('./pages/CallbackRequests'));

const LiveTickData = lazyWithRetry(() => import('./pages/trade/LiveTickData'));
const HistoricalData = lazyWithRetry(() => import('./pages/trade/HistoricalData'));
const ZeroValDiff = lazyWithRetry(() => import('./pages/trade/ZeroValDiff'));

const NotFound404 = lazyWithRetry(() => import('./pages/NotFound404'));

export default function AppRoutes() {
    return (
        <Routes>
            <Route path="/" element={<PublicLayout />}>
                <Route index element={<Home page="Home" />} />
                <Route path="/about" element={<Home page="About_Us" />} />
                <Route path="/approach" element={<Home page="Our_Approach" />} />
                <Route path="/product" element={<Home page="Product" />} />
                <Route path="/research" element={<Home page="Research" />} />
                {/* <Route path="/learning" element={<Home page="Learning" />} /> */}
                <Route path="/learning" element={<Learning />} />
                <Route path="/free-trial" element={<Home page="Free_Trial" />} />
                <Route path="/careers" element={<Home page="Careers" />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/page-learning/:pageId" element={<Learning />} />
                <Route path="/page-content/:pageParam" element={<PageContent />} />
                <Route path="/page-content/:pageParam/:pageId" element={<PageContent />} />
                <Route path="/page/:slug" element={<CmsPageContent />} />
            </Route>

            <Route path="/" element={<PublicLayout nopad />}>
                <Route path="/support" element={<Support />} />
            </Route>

            <Route path="/" element={<DefaultLayout />}>
                <Route path="dashboard" element={<Dashboard />} />

                <Route path="roles" element={<Roles />} />
                <Route path="users" element={<Users />} />
                <Route path="change-password" element={<ChangePassword />} />
                <Route path="profile" element={<Profile />} />

                {/* <Route path="clients" element={<Clients />} /> */}

                <Route path="trade">
                    <Route path="tick-data" element={<LiveTickData />} />
                    <Route path="historical-data" element={<HistoricalData />} />
                    <Route path="historical-data/:token" element={<HistoricalData />} />
                    <Route path="zero-val-diff" element={<ZeroValDiff />} />
                    <Route path="zero-val-diff/:token" element={<ZeroValDiff />} />
                </Route>

                <Route path="products" element={<Products />} />
                <Route path="page-banners" element={<PageBanners />} />
                <Route path="callback-requests" element={<CallbackRequests />} />
            </Route>

            <Route path="*" element={<NotFound404 />} />
        </Routes>
    );
}