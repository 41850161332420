import axios from "../axios";

class AuthService {
    constructor() {
        this.lsKey = 'tradesimplyio';
    }

    login(data) {
        return axios.post("login", data);
    }

    fetchModules() {
        return axios.get("auth/getModules");
    }

    /** */
    setLoginInfoLocalStorage = (data) => {
        const lsData = {
            user_id: data.user_id,
            token: data.token,
            name: data.name,
            modules: data.modules,
            user_type: data.type,
            is_client_admin: data.is_client_admin,
            is_admin: data.is_admin,
            img_url: data.img_url,
        }
        window.localStorage[this.lsKey] = JSON.stringify(lsData);
    }
    getLSData = () => {
        try {
            let lsData = window.localStorage[this.lsKey];
            if (lsData) {
                lsData = JSON.parse(lsData);
            } else {
                lsData = {};
            }

            return lsData;
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    setLSData = (newData) => {
        window.localStorage[this.lsKey] = JSON.stringify(newData);
    }

    getLSDataByKey = (k) => {
        try {
            let lsData = window.localStorage[this.lsKey];
            if (lsData) {
                lsData = JSON.parse(lsData);
            } else {
                lsData = {};
            }

            const v = lsData[k];
            if (typeof v === "undefined") {
                return null;
            }
            return v || null;
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    getUserId = () => {
        return this.getLSDataByKey('user_id') || '';
    }
    getToken = () => {
        return this.getLSDataByKey('token') || '';
    }
    getUserName = () => {
        return this.getLSDataByKey('name') || '';
    }
    getModules = () => {
        return this.getLSDataByKey('modules') || {};
    }
    getUserType = () => {
        return this.getLSDataByKey('user_type') || '';
    }
    isClientAdmin = () => {
        return (this.getLSDataByKey('is_client_admin') || 0) * 1;
    }
    isAdmin = () => {
        return (this.getLSDataByKey('is_admin') || 0) * 1;
    }
    profilePicUrl = () => {
        return this.getLSDataByKey('img_url') || '';
    }
    currency = () => {
        return 'INR';
    }
    currencySymbol = () => {
        return '₹';
    }
    isLogged = () => {
        const token = this.getLSDataByKey('token');
        return token ? true : false;
    }
    logout = (e, navigate) => {
        if (e) e.preventDefault();
        window.localStorage.removeItem(this.lsKey);
        /* const theme = window.localStorage['TYPE_OF_THEME'];
        const isTradingRangeOpen = window.localStorage['isTradingRangeOpen'];
        const tradingRangeData = window.localStorage['tradingRangeData'];
        const tradingRangeDataTrade = window.localStorage['tradingRangeDataTrade'];

        window.localStorage.clear();
        window.sessionStorage.clear();

        window.localStorage['TYPE_OF_THEME'] = theme;
        window.localStorage['isTradingRangeOpen'] = isTradingRangeOpen;
        window.localStorage['tradingRangeData'] = tradingRangeData;
        window.localStorage['tradingRangeDataTrade'] = tradingRangeDataTrade; */

        if (typeof navigate !== "undefined") {
            navigate('/');
        } else {
            window.location.reload();
        }
    }
}

export default new AuthService();