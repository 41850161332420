/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import ChatWithUsButton from './ChatWithUsButton';
import RequestCallbackButton from './RequestCallbackButton';

export default function SocialIcons({ showcallback = false }) {
    return (
        <div className='d-flex align-items-center' style={{ gap: '10px' }}>
            <a target="_blank" href="https://www.facebook.com/Tradesimply08" rel="noreferrer">
                <img src="assets/facebook.png" alt="" width="38px" />
            </a>

            <a target="_blank" href="https://www.instagram.com/tradesimply08" rel="noreferrer">
                <img src="assets/instagram.png" alt="" width="38px" />
            </a>

            <a target="_blank" href="https://www.linkedin.com/company/tradesimply-io/" rel="noreferrer">
                <img src="assets/linkedIn.png" alt="" width="38px" />
            </a>

            <a target="_blank" href="https://twitter.com/TradeSimply08" rel="noreferrer">
                <img src="assets/twitter.png" alt="" width="38px" />
            </a>

            <a target="_blank" href="https://www.youtube.com/channel/UCnq0njbXFyH9bRVZX17pmVg" rel="noreferrer">
                <img src="assets/youtube.png" alt="" width="38px" />
            </a>

            <a target="blank" href={`https://wa.me/9310231161`} rel="noreferrer">
                {/* <img src="assets/whatsaap.png" alt="" width="40px" /> */}
                <ChatWithUsButton />
            </a>

            {showcallback && <RequestCallbackButton />}

            <a target="_blank" href="https://tradesimply-connect.zohocampfire.in/" rel="noreferrer" title='Join @connect forum'>
                <img src="assets/connect.jpeg" alt="" width="40px" style={{ borderRadius: '50%' }} />
            </a>
        </div>
    );
}