/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { QUOTES } from "../../constants";
import AuthService from "../../services/AuthService";
import CustomerService from "../../services/CustomerService";
import RequestCallbackButton from '../../pages/public/tpl/RequestCallbackButton';
import ChatWithUsButton from '../../pages/public/tpl/ChatWithUsButton';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import TPic1 from './icons/11.png';
import TPic2 from './icons/12.png';
import TPic3 from './icons/13.png';
import TPic4 from './icons/14.png';
import TPic5 from './icons/15.png';
import TPic6 from './icons/16.png';

import {
    Input,
    Button,
    Grid,
    Modal,
    message,
    Menu,
    Row,
    Col,
    Carousel
} from 'antd';

import {
    CloseOutlined
} from '@ant-design/icons';

//const $ = window.$;

const useOnLocationChange = (handleLocationChange) => {
    const location = useLocation();
    useEffect(() => handleLocationChange(location), [location, handleLocationChange]);
};

export default function PublicHeader() {
    const screens = Grid.useBreakpoint();
    const isDesktop = screens.xxl || screens.xl || screens.lg || screens.md;
    const { isHomeChartMaximize } = useSelector((state) => state.common);
    const [activeMenu, setActiveMenu] = useState('/');
    const navigate = useNavigate();
    const loginRef = useRef({});
    const isLogged = AuthService.isLogged();
    //const ww = $(window).width();
    const modules = AuthService.getModules();

    const logout = () => {
        AuthService.logout(0, navigate);
    }

    let menuItems = [
        { label: <Link to="/about">About Us</Link>, key: '/about', visible: 'Y' },
        //{ label: <div onClick={() => loginRef.current.open()}>FREE TRIAL</div>, key: 'free-trial', visible: 'Y' },
        { label: <Link to="/free-trial">FREE TRIAL</Link>, key: '/free-trial', visible: 'Y' },
        { label: <Link to="/careers">Careers</Link>, key: '/careers', visible: 'Y' },
        { label: <Link to="/support">Support</Link>, key: '/support', visible: 'Y' }
    ];

    let menuItems2 = [
        { label: 'Dashboard', key: '/dashboard', visible: (isLogged && modules.dashboard) ? 'Y' : 'N' },
        { label: 'Home', key: '/', visible: 'Y', active: activeMenu === '/' ? 'active' : '' },
        { label: 'Our Approach', key: '/approach', visible: 'Y', active: activeMenu === '/approach' ? 'active' : '' },
        { label: 'Product', key: '/product', visible: 'Y', active: activeMenu === '/product' ? 'active' : '' },
        { label: 'Research', key: '/research', visible: 'Y', active: activeMenu === '/research' ? 'active' : '' },
        { label: 'Learning', key: '/learning', visible: 'Y', active: activeMenu === '/learning' ? 'active' : '' },
    ];

    if (!isDesktop) {
        const menues2 = [];
        menuItems2.forEach(v => {
            let a = { ...v };
            a.label = <Link to={v.key}>{v.label}</Link>;
            menues2.push(a);
        });
        menuItems = menues2.filter(rw => rw.visible === 'Y').concat(menuItems);
    }

    useOnLocationChange((loc) => {
        setActiveMenu(loc.pathname);
    });

    useEffect(() => {
        setInterval(() => {
            if (!AuthService.isLogged()) {
                loginRef.current.open();
            }
        }, 1000 * 60 * 3);
    }, []);

    return (
        <div>
            <div className='hdr-top-strip'>
                <div className='hpcontainer'>
                    <div className={isDesktop ? 'd-flex align-items-center justify-content-between' : ''} style={{ minHeight: 36 }}>
                        {isDesktop &&
                            <div className='ellipsis' style={{ height: 20, maxWidth: 800 }}>
                                <Quotes />
                            </div>
                        }
                        <div>
                            <Menu items={menuItems} selectedKeys={activeMenu} mode="horizontal" style={{ minWidth: isDesktop ? '450px' : '100%' }} />
                        </div>
                    </div>
                </div>
            </div>

            {!isHomeChartMaximize &&
                <div className='hdr-grey-bg'>
                    <div className='hpcontainer'>
                        <div className='d-flex align-items-center justify-content-between'>
                            <div>
                                <Link to="/">
                                    <img src="logo.png" width={isDesktop ? "200px" : "150px"} alt="" />
                                </Link>
                            </div>

                            <div className={`d-flex align-items-center hdr-nav`}>
                                {isDesktop &&
                                    <>
                                        {menuItems2.filter(rw => rw.visible === 'Y').map((rw, i) => (
                                            <Link key={i} to={rw.key} className={rw.active}>{rw.label}</Link>
                                        ))}
                                    </>
                                }

                                {isLogged ? (
                                    <>
                                        <div className='bold600 mr10'>{AuthService.getUserName()}</div>
                                        <Button danger onClick={logout} style={{ borderRadius: '6px' }}>Logout</Button>
                                    </>
                                ) : (
                                    <>
                                        <Button danger onClick={() => loginRef.current.open()} style={{ borderRadius: '6px' }}>Login</Button>
                                        <Button type='danger' className='ml8' onClick={() => loginRef.current.open('register')} style={{ borderRadius: '6px' }}>Register</Button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            }
            <Login refOb={loginRef} isDesktop={isDesktop} />
        </div>
    );
}

function TPicIcons({ sm = false }) {
    let span = sm ? 4 : 8;
    return (
        <Row gutter={[8, 8]}>
            <Col span={span}>
                <img alt="" src={TPic1} style={{ maxWidth: '100%' }} />
            </Col>
            <Col span={span}>
                <img alt="" src={TPic2} style={{ maxWidth: '100%' }} />
            </Col>
            <Col span={span}>
                <img alt="" src={TPic3} style={{ maxWidth: '100%' }} />
            </Col>
            <Col span={span}>
                <img alt="" src={TPic4} style={{ maxWidth: '100%' }} />
            </Col>
            <Col span={span}>
                <img alt="" src={TPic5} style={{ maxWidth: '100%' }} />
            </Col>
            <Col span={span}>
                <img alt="" src={TPic6} style={{ maxWidth: '100%' }} />
            </Col>
        </Row>
    )
}

function HeadingLine({ isDesktop }) {
    return (
        <div className={`text-center bold600 ${isDesktop ? '' : 'fs11'}`}>
            <div className='mb5'>Welcome to the Future of Algo Trading & Investing!</div>
            <div>With this Free Trial you will get access to Trendz @NSE30</div>
        </div>
    )
}

function TermsLine() {
    return (
        <div>Terms & Conditions applied. <a href="/terms" target="_blank">Know more</a></div>
    )
}

function Login({ refOb, isDesktop }) {
    const [showModal, setShowModal] = useState(false);
    const [formType, setFormType] = useState('login');
    const handleCancel = () => {
        setShowModal(false);
    }

    refOb.current = {
        open: (type) => {
            setFormType(type || 'login');
            setShowModal(true);
        }
    }

    return (
        <Modal
            closable={false}
            open={showModal}
            onCancel={handleCancel}
            destroyOnClose
            maskClosable={false}
            centered
            width={800}
            footer={null}
            bodyStyle={{ padding: 0, overflow: 'unset' }}
        >
            <div className='position-relative'>
                <div className='cpointer' style={{ position: 'absolute', zIndex: 10, top: isDesktop ? '-5px' : '3px', right: isDesktop ? '-40px' : '0px' }} onClick={handleCancel}>
                    <CloseOutlined className="fs34" style={{ color: isDesktop ? '#fff' : '#000' }} />
                </div>
                <div className='d-flex'>
                    {isDesktop &&
                        <div className='w350' style={{ background: '#2874f0', color: '#fff' }}>
                            <div className='pt20 pb30 pl30 pr30'>
                                <h1 style={{ color: '#fff' }}>{formType === 'login' ? 'Login' : 'Register'}</h1>

                                <TPicIcons />

                                <div className='pt15 fs18 mb15'>
                                    Having problem?
                                </div>

                                <Row gutter={[8, 8]}>
                                    <Col span="24" className='text-center'>
                                        <RequestCallbackButton />
                                    </Col>
                                    <Col span="24" className='text-center'>
                                        <ChatWithUsButton />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    }
                    <div style={isDesktop ? { width: `calc(100% - 350px)` } : null}>
                        <div className='overflow-hidden'>
                            <div className='pt30 pb30 pl30 pr30'>
                                {formType === 'login' && (
                                    <>
                                        <LoginForm isDesktop={isDesktop} />
                                        <div className='pt20'>
                                            <div className='bold600 link' onClick={() => setFormType('register')}>New to TradeSimply? Create an account</div>
                                        </div>
                                    </>
                                )}

                                {formType === 'register' && (
                                    <>
                                        <RegisterForm isDesktop={isDesktop} />
                                        <div className='pt20'>
                                            <div className='bold600 link' onClick={() => setFormType('login')}>Existing User? Login</div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

function LoginForm({ isDesktop }) {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState({});

    const submit = () => {
        message.destroy();
        setLoading(true);
        AuthService.login(values).then(({ data }) => {
            AuthService.setLoginInfoLocalStorage(data);
            navigate('/dashboard');
        }).catch(e => {
            message.error(e.message);
            setLoading(false);
        });
    }

    return (
        <div>
            <div className='mb30'>
                <HeadingLine isDesktop={isDesktop} />
                {!isDesktop &&
                    <div className='mt5'>
                        <TPicIcons sm />
                    </div>
                }
            </div>
            <Row gutter={[16, 30]}>
                <Col span="24">
                    <div className='fs13 text-secondary bold600 text-dark'>Email/Username</div>
                    <Input
                        className='material'
                        placeholder="Enter email-id/mobile number"
                        size="large"
                        value={values.username || ''}
                        onChange={e => setValues({ ...values, username: e.target.value })}
                    />
                </Col>

                <Col span="24">
                    <div className='fs13 text-secondary bold600 text-dark'>Password</div>
                    <Input.Password
                        className='material'
                        placeholder="Enter password"
                        size="large"
                        value={values.password || ''}
                        onChange={e => setValues({ ...values, password: e.target.value })}
                    />
                </Col>

                <Col span="24">
                    <div className='pt10'>
                        <div>* Your registered email-id/mobile number is your username</div>
                        <TermsLine />
                    </div>
                    <div className='pt10'>
                        <Button type="primary" size="large" block loading={loading} onClick={submit}>Login</Button>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

function RegisterForm({ isDesktop }) {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState({});

    const login = () => {
        setLoading(true);
        AuthService.login({ username: values.email, password: values.password }).then(({ data }) => {
            AuthService.setLoginInfoLocalStorage(data);
            navigate('/dashboard');
        }).catch(e => {
            message.error(e.message);
            setLoading(false);
        });
    }

    const submit = (e) => {
        if (e) e.preventDefault();
        message.destroy();
        setLoading(true);
        CustomerService.register(values).then(({ data }) => {
            message.success(data.message);
            login();
        }).catch(e => {
            message.error(e.message);
            setLoading(false);
        });
    }

    return (
        <div>
            <div className='mb30'>
                <HeadingLine isDesktop={isDesktop} />
                {!isDesktop &&
                    <div className='mt5'>
                        <TPicIcons sm />
                    </div>
                }
            </div>
            <Row gutter={[16, 20]}>
                <Col span="24">
                    <Row gutter={[16, 8]}>
                        <Col span="12">
                            <div className='fs13 text-secondary bold600 text-dark req'>First Name</div>
                            <Input
                                className='material'
                                placeholder="Enter first name"
                                //size="large"
                                value={values.fname || ''}
                                onChange={e => setValues({ ...values, fname: e.target.value })}
                            />
                        </Col>
                        <Col span="12">
                            <div className='fs13 text-secondary bold600 text-dark req'>Last Name</div>
                            <Input
                                className='material'
                                placeholder="Enter last name"
                                //size="large"
                                value={values.lname || ''}
                                onChange={e => setValues({ ...values, lname: e.target.value })}
                            />
                        </Col>
                    </Row>
                </Col>

                <Col span="24">
                    <div className='fs13 text-secondary bold600 text-dark req'>Email</div>
                    <Input
                        className='material'
                        placeholder="Enter email-id"
                        //size="large"
                        value={values.email || ''}
                        onChange={e => setValues({ ...values, email: e.target.value })}
                    />
                </Col>

                <Col span="24">
                    <div className='fs13 text-secondary bold600 text-dark req'>Mobile</div>
                    <div className='position-relative'>
                        <div className='text-secondary' style={{ position: 'absolute', left: '0px', top: '4px' }}>+91</div>
                        <Input
                            className='material aipl30'
                            placeholder="Enter mobile number"
                            //size="large"
                            maxLength="10"
                            value={values.mobile || ''}
                            onChange={e => setValues({ ...values, mobile: e.target.value })}
                        />
                    </div>
                </Col>

                <Col span="24">
                    <div className='fs13 text-secondary bold600 text-dark'>Password</div>
                    <Input.Password
                        className='material'
                        placeholder="Enter password"
                        //size="large"
                        value={values.password || ''}
                        onChange={e => setValues({ ...values, password: e.target.value })}
                    />
                </Col>

                <Col span="24">
                    <div className='fs13 text-secondary bold600 text-dark'>Confirm Password</div>
                    <Input.Password
                        className='material'
                        placeholder="Re-type password"
                        //size="large"
                        value={values.repassword || ''}
                        onChange={e => setValues({ ...values, repassword: e.target.value })}
                    />
                </Col>

                <Col span="24">
                    <div className='pt10'>
                        <TermsLine />
                    </div>
                    <div className='pt10'>
                        <Button type="primary" size="large" block loading={loading} onClick={submit}>Register</Button>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

function Quotes() {
    return (
        <div>
            <Carousel effect="fade" autoplay={true} autoplaySpeed={10000} dots={false}>
                {QUOTES.map((v, i) => (
                    <div key={i}>
                        <span style={{ color: '#87cefa' }}>"{v.quote}"</span> <span style={{ color: 'white' }}> - {v.author}</span>
                    </div>
                ))}
            </Carousel>
        </div>
    )
}