import axios from "axios";
import util from "./util";
import AuthService from "./services/AuthService";

export const apiUrl = () => {
    let url = window.location.protocol + "//" + window.location.hostname + "/pro/stock-trade/api/";
    //url = "https://api.tradesimply.io";
    if (window.location.href.indexOf("app.tradesimply.io") >= 0) {
        //url = window.location.protocol + "//20.244.0.179/stock-trade/api";
        url = window.location.protocol + "//api.tradesimply.io";
    }

    return url;
}

const axiosInstance = axios.create({
    baseURL: apiUrl(),
    //withCredentials:true
});

axiosInstance.interceptors.request.use(
    (config) => {
        config.headers.authorization = AuthService.getToken();
        config.headers.timezoneoffset = util.getTimezoneOffset();
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (typeof error.response === "undefined") {
            error.response = {};
        } else {
            if (typeof error.response.data === "object") {

                if (typeof error.response.data?.message !== "undefined") {
                    error.message = error.response.data?.message;
                }
                if (error.response.data.loggedOut === 1) {
                    AuthService.logout();
                }
            }
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;