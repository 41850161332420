/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Avatar, Dropdown, Menu, Grid } from 'antd';
import { DownOutlined, UserOutlined, ProfileOutlined, SettingOutlined, LogoutOutlined } from '@ant-design/icons';
//import util from "../../util";
import AuthService from "../../services/AuthService";
//const { SubMenu } = Menu;
//let $=window.$;

const useOnLocationChange = (handleLocationChange) => {
    const location = useLocation();
    useEffect(() => handleLocationChange(location), [location, handleLocationChange]);
};

export default function Header() {
    const screens = Grid.useBreakpoint();
    const isDesktop = screens.xxl || screens.xl || screens.lg;
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const [activeMenu, setActiveMenu] = useState('dashboard');
    const modules = AuthService.getModules();

    //const modules = AuthService.getModules();
    const profilePicUrl = AuthService.profilePicUrl();

    const logout = () => {
        AuthService.logout(0, navigate);
    }

    const onMenuChange = (e) => {
        setActiveMenu(e.key);
    }

    useOnLocationChange((loc) => {
        setActiveMenu(loc.pathname);
    });

    useEffect(() => {
        setActiveMenu(pathname);
    }, []);


    return (
        <div className="navbar-default d-flex align-items-center">
            {/* <h3 className="m0 nowrap uc">Trade Simply</h3> */}
            <div>
                <img src="logo.png" alt="" width={isDesktop ? "150" : "150"} />
            </div>
            <div style={{ width: `calc(100% - 290px)` }}>
                <Menu mode="horizontal" selectedKeys={activeMenu} onClick={e => onMenuChange(e)}>
                    <Menu.Item key="/">
                        <Link to="/">Home</Link>
                    </Menu.Item>
                    {!!modules.dashboard &&
                        <Menu.Item key="/dashboard">
                            <Link to="/dashboard">Dashboard</Link>
                        </Menu.Item>
                    }
                    {!!modules.tick_data &&
                        <Menu.Item key="/trade/tick-data">
                            <Link to="/trade/tick-data">Tick Data</Link>
                        </Menu.Item>
                    }

                    {!!modules.historical_data &&
                        <Menu.Item key="/trade/historical-data">
                            <Link to="/trade/historical-data">Historical Data</Link>
                        </Menu.Item>
                    }
                    {!!modules.zero_value_diff &&
                        <Menu.Item key="/trade/zero-val-diff">
                            <Link to="/trade/zero-val-diff">Zero Val Diff</Link>
                        </Menu.Item>
                    }
                    {!!modules.manage_products &&
                        <Menu.Item key="/products">
                            <Link to="/products">Products</Link>
                        </Menu.Item>
                    }
                    {!!modules.page_banners &&
                        <Menu.Item key="/page-banners">
                            <Link to="/page-banners">Page Banners/Videos</Link>
                        </Menu.Item>
                    }
                    {!!modules.manage_users &&
                        <>
                            <Menu.Item key="/roles">
                                <Link to="/roles">Roles</Link>
                            </Menu.Item>
                            <Menu.Item key="/users">
                                <Link to="/users">Users</Link>
                            </Menu.Item>
                        </>
                    }
                    {!!modules.callback_request &&
                        <Menu.Item key="/callback-requests">
                            <Link to="/callback-requests">Callback Requests</Link>
                        </Menu.Item>
                    }
                </Menu>
            </div>

            <div className="ml-auto d-flex align-items-center">
                <Dropdown
                    placement="bottomRight"
                    arrow
                    trigger={["click"]}
                    overlay={(
                        <Menu>
                            <Menu.Item key="1">
                                <Link to="/profile"><ProfileOutlined /> Profile</Link>
                            </Menu.Item>
                            <Menu.Item key="2">
                                <Link to="/change-password"><SettingOutlined /> Change Password</Link>
                            </Menu.Item>
                            <Menu.Item key="3">
                                <div className="cpointer" onClick={logout}><LogoutOutlined /> Logout</div>
                            </Menu.Item>
                            {/* <Menu.Item key="4">
                                <div className="cpointer" onClick={() => { window.localStorage['TYPE_OF_THEME'] = 'light'; window.location.reload(); }}>Light Mode</div>
                            </Menu.Item>
                            <Menu.Item key="5">
                                <div className="cpointer" onClick={() => { window.localStorage['TYPE_OF_THEME'] = 'dark'; window.location.reload(); }}>Dark Mode</div>
                            </Menu.Item> */}
                        </Menu>
                    )}
                >
                    <div className="cpointer d-flex align-items-center">
                        <div className="pr10">
                            {profilePicUrl !== '' ? (
                                <Avatar src={profilePicUrl} />
                            ) : (
                                <Avatar icon={<UserOutlined />} style={{ color: "#f56a00" }} />
                            )}
                        </div>
                        <div className="ml-auto d-flex align-items-center">
                            {isDesktop &&
                                <div className="ellipsis pr10" style={{ maxWidth: 100 }}>
                                    {/* <Tooltip title={AuthService.getUserName()}> */}
                                    {AuthService.getUserName()}
                                    {/* </Tooltip> */}
                                </div>
                            }
                            <div>
                                <DownOutlined className="fs13" />
                            </div>
                        </div>
                    </div>
                </Dropdown>
            </div>
        </div>
    )
}