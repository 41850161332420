/* eslint-disable react-hooks/exhaustive-deps */
import './public.css';
import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import PublicHeader from './PublicHeader';
import PublicFooter from './PublicFooter';
import AuthService from "../../services/AuthService";

//import util from '../util';
let fetchModuleInterval = null;

export default function PublicLayout({nopad=false}) {
    const [render, setRender] = useState(0);

    const fetchModules = () => {
        AuthService.fetchModules().then(({ data }) => {
            const newData = AuthService.getLSData();
            newData.modules = data.modules;
            AuthService.setLSData(newData);
            setRender(render + 1);
        }).catch(e => {
        })
    }

    useEffect(() => {
        if (!AuthService.isLogged()) {
            return;
        }

        fetchModules();
        fetchModuleInterval = setInterval(() => {
            fetchModules();
        }, 5000);

        return () => {
            if (fetchModuleInterval) {
                clearInterval(fetchModuleInterval);
            }
        }
    }, []);

    return (
        <div className="parent publicpage d-flex" style={{ flexDirection: 'column' }}>
            <PublicHeader />
            <div className={`${nopad?'':'hpcontainer pt10 pb30'}`}>
                <Outlet />
            </div>

            <PublicFooter />
        </div>
    )
}